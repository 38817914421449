body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

em {
  background: cyan;
  font-style: normal;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #8e43e7, #00aeff);
  color: #fff;
  margin-bottom: 1rem;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' \25B8   ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: -webkit-flex;
  display: flex;
}

.search-panel__filters {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.search-panel__results {
  display: grid;
  grid-template-columns: 5% 90% 5%;
  width: 100%;
}
article{
  grid-area: 1/3;
  display: -webkit-flex;
  display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
}
.searchbox {
  margin-bottom: 2rem;
  grid-column:2;
}
div.ais-Hits{
  margin-bottom: 2rem;
  grid-column: 2;
}

ul.ais-Hits-list{
  display: grid;
  grid-template-columns: 45% 45%;
  grid-template-columns: minmax(1fr,300px);
  grid-gap: 10px;
  margin: 0;
  padding: 0;
}
li.ais-Hits-item{
  width: 100%;
  overflow: hidden;
}
.pagination {
  margin-top: 2rem;
  text-align: center;
  grid-column: 2;
}
.MuiTypography-gutterBottom {
  max-height: 2000px;
  overflow-wrap: break-word;
  
}

li{
  list-style: none;
}


input,
label,
textarea {
  font-family: 'Ubuntu', sans-serif;
  display: block;
  margin: 10px;
  padding: 5px;
  border: none;
  font-size: 12px;
}

textarea:focus,
input:focus {
  outline: 0;
}
/* Question */

input.question,
textarea.question {
  font-size: 48px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 80%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

input.question + label,
textarea.question + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 10%;
  border-top: 1px solid red;
  transition: width 0.4s ease;
  height: 0px;
}

input.question:focus + label,
textarea.question:focus + label {
  width: 80%;
}

input.question:focus,
input.question:valid {
  padding-top: 35px;
}

textarea.question:valid,
textarea.question:focus {
  margin-top: 35px;
}

input.question:focus + label > span,
input.question:valid + label > span {
  top: -100px;
  font-size: 22px;
  color: #333;
}

textarea.question:focus + label > span,
textarea.question:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}

input.question:valid + label,
textarea.question:valid + label {
  border-color: green;
}

input.question:invalid,
textarea.question:invalid {
  box-shadow: none;
}

input.question + label > span,
textarea.question + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #8F8F8F;
  font-size: 48px;
  top: -66px;
  left: 0px;
  z-index: -1;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

input[type="submit"] {
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: #EEE;
}

input[type="submit"]:active {
  background: #999;
}

input.question:valid ~ input[type="submit"], textarea.question:valid ~ input[type="submit"] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}

input.question:invalid ~ input[type="submit"], textarea.question:invalid ~ input[type="submit"] {
  display: none;
}

@-webkit-keyframes appear {
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}


form.ais-SearchBox-form {
  display: grid;
  grid-gap:10px;
  grid-template-columns: 80% 10%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

ul.ais-Pagination-list {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
span.ais-Highlight-nonHighlighted {
  padding: 0;
  margin: 0;
  display: inline;
}

span.ais-Highlight {
  margin: 0;
  padding: 0;
}


.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem;
  position: relative;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

button.ais-SearchBox-reset {
  grid-column: 2;
  grid-row: 1;
}

button.ais-SearchBox-submit {
  grid-column: 2;
  grid-row: 1;
  height: 80%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

svg.ais-SearchBox-submitIcon {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 800px) {
  ul.ais-Hits-list{
    grid-template-columns: 90%;
  }
}
